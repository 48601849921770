html,
body {
  margin: 0;
  padding: 0;
  font-feature-settings: 'tnum';
}

h3 {
  margin: 0;
}

img,
svg {
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

b {
  font-weight: 600;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.align-right {
  text-align: right;
}

/*
 Theme
*/

.rdot-login-splash {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/RockefellerLogoBlackBG.svg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-size: 326px 43px;
  background-color: #000;
}

table.simple-table td,
table.simple-table td {
  padding: 5px;
}

header .ms-Button {
  height: 40px;
}

header .ms-Button--icon {
  width: 40px;
}

.palette-more {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: block;
}

/* LOADING */
@keyframes dot-keyframes {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loading-dots {
  text-align: center;
  width: 100%;
}
.loading-dots--dot {
  -webkit-animation: dot-keyframes 1.5s infinite ease-in-out;
  animation: dot-keyframes 1.5s infinite ease-in-out;
  border-radius: 10px;
  display: inline-block;
  height: 7px;
  width: 7px;
  margin: 0 2px;
  opacity: 0;
}
.loading-dots--dot:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.loading-dots--dot:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@media print {
  .noprint {
    display: none;
  }
  .componentContainer {
    page-break-before: always;
  }
}

@media screen {
  .printonly {
    display: none;
  }
}
