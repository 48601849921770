.aiRevenueOdataList .ms-Button--commandBar {
  background-color: transparent !important;
}
.aiRevenueOdataList .ms-Button--default {
  background-color: transparent;
}
.aiRevenueOdataList .cancel-icon {
  background-color: #005956;
  color: #fff;
}
#airevenuetableFooter {
  background-color: #bba984;
  font-weight: 600;
}
.planTotal table {
  height: 150;
  width: 275;
}

.planTotal table.no-spacing {
  border-spacing: 0;
}
.aiRevenueDashboard {
  background-color: #f3f1ec;
  margin-bottom: 0px;
  padding: 0px !important;
  display: inline-block;
}

.aiRevenueDashboard .focusZone-249 {
  display: block !important;
  min-width: 100%;
  min-height: 1px;
}

#content {
  overflow-y: auto;
  min-height: calc(100vh - 91vh);
  max-height: calc(100vh - 34vh);
  position: relative;
}
